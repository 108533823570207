/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0px !important;
    padding-top: 0px !important;
    font-family: Nunito Sans, Roboto, Marck Script, "Helvetica Neue", sans-serif;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('./assets/fonts/Nunito_Sans/NunitoSans-Regular.ttf');
    src: url('./assets/fonts/Nunito_Sans/NunitoSans-SemiBold.ttf');
    /* src: url('./assets/fonts/Nunito_Sans/NunitoSans-Bold.ttf');
    src: url('./assets/fonts/Nunito_Sans/NunitoSans-BoldItalic.ttf');
    src: url('./assets/fonts/Nunito_Sans/NunitoSans-ExtraBold.ttf');
    src: url('./assets/fonts/Nunito_Sans/NunitoSans-ExtraBoldItalic.ttf');
    src: url('./assets/fonts/Nunito_Sans/NunitoSans-ExtraLight.ttf');
    src: url('./assets/fonts/Nunito_Sans/NunitoSans-ExtraLightItalic.ttf');
    src: url('./assets/fonts/Nunito_Sans/NunitoSans-Italic.ttf');
    src: url('./assets/fonts/Nunito_Sans/NunitoSans-Light.ttf');
    src: url('./assets/fonts/Nunito_Sans/NunitoSans-LightItalic.ttf');
    src: url('./assets/fonts/Nunito_Sans/NunitoSans-SemiBoldItalic.ttf');
    src: url('./assets/fonts/Nunito_Sans/NunitoSans-Black.ttf');
    src: url('./assets/fonts/Nunito_Sans/NunitoSans-BlackItalic.ttf'); */
}

@font-face {
    font-family: 'Marck Script';
    src: url('./assets/fonts/Marck_Script/MarckScript-Regular.ttf');
}


/* label style */

.mat-tab-label {
    background: white;
    color: #391410;
}


/* focus style */

.mat-tab-group.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-group.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus {
    background: #391410;
    color: white
}


/* ink bar style */

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background: red !important;
    height: 2px;
}


/* personalizadas para el panel  */

.margeTop {
    margin-top: 2.9em !important;
}

input[type="checkbox"] {
    box-shadow: inherit !important;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
    border-color: #391410;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px#391410;
    outline: 0 none;
}

.make-rojo {
    background-color: #d01d17;
}

.make-letra-rojo {
    color: #d01d17;
}

.make-boton-rojo {
    color: #d01d17;
    border-color: #d01d17;
}

.make-borde-rojo {
    border-color: #d01d17;
}

.make-boton-rojo-relleno {
    color: #e9e3e3 !important;
    border-color: #d01d17 !important;
    background-color: #d01d17 !important;
}

.make-cafeobscuro {
    background-color: #391410;
}

.make-letra-cafeobscuro {
    color: #391410;
}

.make-boton-cafeobscuro {
    color: #391410;
    border-color: #391410;
}

.make-boton-cafeobscuro-relleno {
    color: #e9e3e3 !important;
    border-color: #391410 !important;
    background-color: #391410 !important;
}

.make-borde-cafeoscuro {
    border-color: #391410;
}

.make-rosado {
    background-color: #e32729;
}

.make-letra-rosado {
    color: #e32729;
}

.make-boton-rosado {
    color: #e32729;
    border-color: #e32729;
}

.make-boton-rosado-relleno {
    color: #e9e3e3;
    border-color: #e32729;
    background-color: #e32729;
}

.make-gris {
    background-color: #445576;
}

.make-letra-gris {
    color: #445576;
}

.make-boton-gris {
    color: #445576;
    border-color: #445576;
}

.make-boton-gris-relleno {
    color: #e9e3e3;
    border-color: #445576;
    background-color: #445576;
}

.make-amarillo {
    background-color: #e97a0d;
}

.make-letra-amarillo {
    color: #e97a0d;
}

.make-boton-amarillo {
    color: #e97a0d;
    border-color: #e97a0d;
}

.make-boton-amarillo-relleno {
    color: #e9e3e3;
    border-color: #e97a0d;
    background-color: #e97a0d;
}

.make-cafeclaro {
    background-color: #8c1819;
}

.make-letra-cafeclaro {
    color: #8c1819;
}

.make-boton-cafeclaro {
    color: #8c1819;
    border-color: #8c1819;
}

.make-boton-cafeclaro-relleno {
    color: #e9e3e3;
    border-color: #8c1819;
    background-color: #8c1819;
}

* {
    font-family: 'Nunito Sans' !important;
}

.make-Letra-separacion {
    letter-spacing: 1.5pt;
}

.margin-left {
    margin-left: 40px !important;
}


/* .make-Letra-Encabezado-tabla {
    font-size: 14px;
    text-align: center;
}

.make-Letra-celda-tabla {
    font-size: 14px;
} */